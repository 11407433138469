import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
    dsn: "https://df01f89575254b91be2d9bddb06bfc37@o253637.ingest.sentry.io/5704044",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
});

import "./style.css";

function loadPage(name: string, p: string) {
    console.log("loading page", name);
    document.getElementById("container").innerHTML = p;
    document.getElementById("container")
        .className = "container " + name;
    document.body.id = name;
}
const el = (el: string) => document.querySelector(el);
function clk(sel: string, fn: (ev: Event) => void) {
    document.querySelectorAll(sel).forEach(v => {
        v.addEventListener("click", fn);
    });
}

let name = "";

//@ts-ignore
import p1 from "./pages/o1.html";
function loadP1() {
    const transaction = Sentry.startTransaction({
        name: "game start"
    });

    name = (el("#name") as HTMLInputElement).value;
    if (name.startsWith("амогус")) {
        document.body.classList.add("truth");
        console.log("easter egg activated!");
        transaction.setData("easter", true);
    } else transaction.setData("easter", false);

    transaction.setData("name", name);
    transaction.finish();

    window.localStorage.setItem("page", "p1");
    loadPage("p1", p1);
    clk(".o1-next", loadP2);
}

//@ts-ignore
import p2 from "./pages/o2.html";
function loadP2() {
    window.localStorage.setItem("page", "p2");
    loadPage("p2", p2);
    const code = "6942089253";
    const codeEl = el(".o2-code");
    let codePtr = 0;
    clk(".o2-num", ev => {
        const number = (ev.target as HTMLElement).getAttribute("data-number");
        console.log("pressed number", number);
        if (code.charAt(codePtr) == number) {
            codePtr++;
            codeEl.innerHTML = codeEl.innerHTML + number;
            codeEl.classList.add("o2-code_correct");
            setTimeout(() => {
                codeEl.classList.remove("o2-code_correct");
            }, 500);

            if (codePtr == code.length) {
                (el(".o2-done") as HTMLButtonElement).style.display = "block";
                clk(".o2-done", loadP3);
            }
        } else {
            codeEl.classList.add("o2-code_incorrect");
            setTimeout(() => {
                codeEl.classList.remove("o2-code_incorrect");
            }, 500);
        }
    });
}

//@ts-ignore
import p3 from "./pages/o3.html";
function loadP3() {
    window.localStorage.setItem("page", "p3");
    loadPage("p3", p3);
    let result = "";
    clk(".o3-word", ev => {
        const target = (ev.target as HTMLDivElement);
        if (target.parentElement.classList.contains("o3-sentence")) {
            el(".o3-words").appendChild(target);
            result = result.replace(target.getAttribute("data-index"), "");
        } else {
            el(".o3-sentence").appendChild(target);
            result += target.getAttribute("data-index");
        }
        if (result === "0123456") {
            (el(".o3-done") as HTMLDivElement).style.display = "block";
            clk(".o3-done", loadP4);
        }
    });
}

//@ts-ignore
import p4 from "./pages/o4.html";
function buildMaze(el: HTMLDivElement, maze: string) {
    el.innerHTML = "";
    for (let i of maze) {
        const e = document.createElement("div");
        e.classList.add("maze-i");
        switch (i) {
            case "#":
                e.classList.add("maze-i_wall");
                break;

            case " ":
                e.classList.add("maze-i_empty");
                break;

            case "p":
                e.classList.add("maze-i_part");
                break;
        }
        el.appendChild(e);
    }
}
function getMazeElAt(el: HTMLDivElement, x: number, y: number,
                     sizeX: number): HTMLDivElement {
    return el.children[x + y * sizeX] as HTMLDivElement;
}
function revealAtPos(el: HTMLDivElement, x: number, y: number,
                     sizeX: number) {
    getMazeElAt(el, x, y, sizeX).classList.add("maze-i_seen");
    getMazeElAt(el, x + 1, y, sizeX).classList.add("maze-i_seen");
    getMazeElAt(el, x - 1, y, sizeX).classList.add("maze-i_seen");
    getMazeElAt(el, x, y + 1, sizeX).classList.add("maze-i_seen");
    getMazeElAt(el, x, y - 1, sizeX).classList.add("maze-i_seen");
    getMazeElAt(el, x + 1, y + 1, sizeX).classList.add("maze-i_seen");
    getMazeElAt(el, x - 1, y + 1, sizeX).classList.add("maze-i_seen");
    getMazeElAt(el, x + 1, y - 1, sizeX).classList.add("maze-i_seen");
    getMazeElAt(el, x - 1, y - 1, sizeX).classList.add("maze-i_seen");
}
let parts = 0;
function o4CheckPart(mazeEl: HTMLDivElement, x: number, y: number,
                     sizeX: number) {
    const target = getMazeElAt(mazeEl, x, y, sizeX);
    if (target.classList.contains("maze-i_part")) {
        target.classList.remove("maze-i_part");
        if (++parts === 5) {
            clk(".o4-next", loadP5);
            (el(".o4-next") as HTMLDivElement).style.display = "block";
        }
        el(".o4-parts").innerHTML = parts.toString();
    }
}
function loadP4() {
    window.localStorage.setItem("page", "p4");
    loadPage("p4", p4);
    const mazeEl = el(".o4-maze") as HTMLDivElement;
    const maze =
        "#####################" +
        "#   #         #     #" +
        "# # # ####### # #####" +
        "# #   #   #   #     #" +
        "# ##### # # ####### #" +
        "#   #   # # # p     #" +
        "### ##### # ### ### #" +
        "#   # p # #   # # p #" +
        "# ### # # ### # ### #" +
        "# #   # # #   #   # #" +
        "# ### # # # ##### ###" +
        "#   # #   # p     # #" +
        "### # ### ### ##### #" +
        "#   # #   #   #   # #" +
        "# ### ##### ### # # #" +
        "# #   #   # #   # # #" +
        "# ### # # # # ### # #" +
        "#   #   #   # #   # #" +
        "# # ######### # ### #" +
        "# #   p       #     #" +
        "#####################";
    let posX = 7, posY = 1;
    buildMaze(mazeEl, maze);
    getMazeElAt(mazeEl, posX, posY, 21).classList.add("maze-i_pos");
    revealAtPos(mazeEl, posX, posY, 21);

    clk(".o4-up", () => {
        const target = getMazeElAt(mazeEl, posX, posY - 1, 21);
        if (! target.classList.contains("maze-i_wall")) {
            getMazeElAt(mazeEl, posX, posY, 21)
                .classList.remove("maze-i_pos");
            target.classList.add("maze-i_pos");
            posY--;
            revealAtPos(mazeEl, posX, posY, 21);
            o4CheckPart(mazeEl, posX, posY, 21);
        }
    });
    clk(".o4-left", () => {
        const target = getMazeElAt(mazeEl, posX - 1, posY, 21);
        if (! target.classList.contains("maze-i_wall")) {
            getMazeElAt(mazeEl, posX, posY, 21)
                .classList.remove("maze-i_pos");
            target.classList.add("maze-i_pos");
            posX--;
            revealAtPos(mazeEl, posX, posY, 21);
            o4CheckPart(mazeEl, posX, posY, 21);
        }
    });
    clk(".o4-down", () => {
        const target = getMazeElAt(mazeEl, posX, posY + 1, 21);
        if (! target.classList.contains("maze-i_wall")) {
            getMazeElAt(mazeEl, posX, posY, 21)
                .classList.remove("maze-i_pos");
            target.classList.add("maze-i_pos");
            posY++;
            revealAtPos(mazeEl, posX, posY, 21);
            o4CheckPart(mazeEl, posX, posY, 21);
        }
    });
    clk(".o4-right", () => {
        const target = getMazeElAt(mazeEl, posX + 1, posY, 21);
        if (! target.classList.contains("maze-i_wall")) {
            getMazeElAt(mazeEl, posX, posY, 21)
                .classList.remove("maze-i_pos");
            target.classList.add("maze-i_pos");
            posX++;
            revealAtPos(mazeEl, posX, posY, 21);
            o4CheckPart(mazeEl, posX, posY, 21);
        }
    });
}

//@ts-ignore
import p5 from "./pages/o5.html";
let p5Interval, stopCount = 0, wait = false;
function loadP5() {
    window.localStorage.setItem("page", "p5");
    loadPage("p5", p5);
    let percent = 0;
    const anim = (el(".o5-pointer") as HTMLDivElement)
        .getAnimations()[0];
    anim.cancel();
    anim.play();
    p5Interval = setInterval(() => {
        if (++percent >= 100)
            percent = 0;
    }, 20);
    clk(".o5-skip", loadP6);
    clk(".o5-stop", () => {
        if (wait) return;
        wait = true;
        console.log("percent:", percent);
        if (percent >= 47 && percent <= 51) {
            anim.pause();
            clearInterval(p5Interval);
            console.log("good");
            (el(".o5-skip") as HTMLDivElement)
                .style.display = "block";
            (el(".o5-skip") as HTMLDivElement)
                .innerHTML = "Продолжить";
            return;
        }
        if (stopCount++ >= 5) {
            (el(".o5-skip") as HTMLDivElement).style.display = "block";
        }
        anim.pause();
        clearInterval(p5Interval);
        setTimeout(() => {
            anim.cancel();
            anim.play();
            percent = 0;
            p5Interval = setInterval(() => {
                if (++percent >= 100)
                    percent = 0;
            }, 20);
            wait = false;
        }, 1000);
    });
}

//@ts-ignore
import p6 from "./pages/o6.html";
//@ts-ignore
import badEnding from "./pages/bad-ending.html";
//@ts-ignore
import goodEnding from "./pages/good-ending.html";
//@ts-ignore
import bestEnding from "./pages/best-ending.html";
//@ts-ignore
import brokenPage from "./pages/broken.html";
let correctAnswers = 0, answers = 0;
function loadP6() {
    window.localStorage.setItem("page", "p6");
    loadPage("p6", p6);
    clk(".o6-answer", ev => {
        const tgt = ev.target as HTMLDivElement;
        const groupSel = "[data-group=\"" + tgt.getAttribute("data-group") + "\"]";

        if (el(".o6-shown" + groupSel) != null) return;
        if (! tgt.classList.contains("o6-correct")) {
            el(".o6-correct" + groupSel).classList.add("o6-shown");
        } else correctAnswers++;
        answers++;
        tgt.classList.add("o6-shown");

        if (answers >= 3) {
            clk(".o6-done", () => {
                Sentry.startTransaction({
                    name: "o6 answers"
                })
                    .setData("name", name)
                    .setData("correct", correctAnswers)
                    .finish();

                if (correctAnswers < 2) {
                    loadPage("badEnding", badEnding);
                } else if (correctAnswers == 2) {
                    loadPage("goodEnding", goodEnding);
                } else if (correctAnswers == 3) {
                    loadPage("bestEnding", bestEnding);
                } else {
                    loadPage("broken", brokenPage);
                }
                window.localStorage.setItem("page", "start");
            });
            (el(".o6-done") as HTMLDivElement).style.display = "block";
        }
    });
}

// const page = window.localStorage.getItem("page");
// switch (page) {
//     case "p2": loadP2(); break;
//     case "p3": loadP3(); break;
//     case "p4": loadP4(); break;
//     case "p5": loadP5(); break;
//     case "p6": loadP6(); break;
//     case "p1": loadP1(); break;
//     default:
clk(".start-btn", loadP1);
// }

// loadP6();
// loadPage("badEnding", goodEnding);
